import cn from 'classnames';
import { S1ClientConnectionStatus } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { IconArrowDropDown, IconArrowDropUp } from '../../../../../helpers';
import ConnectedClientRow from './ConnectedClientRow';
import { useConnectedClientsTableViewModel } from './useConnectedClientsTableViewModel';

const FilterSelectContainer: FC = ({ children }) => {
    return (
        <div className="s1-connected-clients__status-filter-container">
            <p>Filter by status:</p>
            {children}
        </div>
    );
};
interface IGenerateHeaderProps {
    requestSort: (columnName: string) => void;
    getClassNamesFor: (columnName: string) => string | undefined;
}
const GenerateHeader: FC<IGenerateHeaderProps> = ({ requestSort, getClassNamesFor }) => {
    const jsxArray = headerProperties.map((value, index) => {
        return (
            <th
                className="s1-connected-clients__header"
                key={'s1-connected-clients__header-' + index}
                onClick={() => requestSort(value.columnName)}
            >
                <div className="patient-list__header-flex">
                    <div
                        className={`patient-list__header-text ${getClassNamesFor(
                            value.columnName,
                        )}`}
                    >
                        {value.headerName}
                    </div>
                    {value.sortable && (
                        <span className={getClassNamesFor(value.columnName)}>
                            <IconArrowDropUp
                                className={'patient-list__header-sort-icon icon-ascending'}
                            />
                            <IconArrowDropDown
                                className={`patient-list__header-sort-icon icon-descending`}
                            />
                        </span>
                    )}
                </div>
            </th>
        );
    });
    return <>{jsxArray}</>;
};
const ConnectedClientsTable: FC = () => {
    const {
        isLoading,
        isError,
        isSuccess,
        s1ClientConnectionStatuses,
        requestSort,
        getClassNamesFor,
        FilterSelect,
    } = useConnectedClientsTableViewModel();

    return (
        <div className="s1-connected-clients__main">
            <FilterSelectContainer>
                <FilterSelect />
            </FilterSelectContainer>
            <table className="s1-connected-clients__containerWidth">
                <thead>
                    <tr className="s1-connected-clients__list-row s1-connected-clients__list-row--sticky">
                        <GenerateHeader
                            requestSort={requestSort}
                            getClassNamesFor={getClassNamesFor}
                        />
                    </tr>
                </thead>
                {isSuccess && s1ClientConnectionStatuses.length > 0 && (
                    <tbody>
                        {s1ClientConnectionStatuses.map((clientConnectionStatus, index) => (
                            <ConnectedClientRow
                                clientConnectionStatus={clientConnectionStatus}
                                index={index}
                                key={`${generateUniqueRowKey(clientConnectionStatus)}` + index}
                            />
                        ))}
                    </tbody>
                )}
            </table>
            {isLoading && (
                <EmptyConnectedClientsTable message={loadingStateMessage} state="loading" />
            )}
            {isError && <EmptyConnectedClientsTable message={errorStateMessage} state="error" />}
            {isSuccess && s1ClientConnectionStatuses.length === 0 && (
                <EmptyConnectedClientsTable message={emptyStateMessage} state="empty" />
            )}
        </div>
    );
};

const emptyStateMessage: string = 'There are no recently active clients';
const errorStateMessage: string = 'An error occurred while fetching connected clients';
const loadingStateMessage: string = 'Loading...';

const EmptyConnectedClientsTable: FC<{ message: string; state: 'loading' | 'error' | 'empty' }> = ({
    message,
    state,
}) => {
    return (
        <div
            className={cn('s1-connected-clients__empty', {
                's1-connected-clients__empty--loading': state === 'loading',
                's1-connected-clients__empty--error': state === 'error',
                's1-connected-clients__empty--empty': state === 'empty',
            })}
        >
            {message}
        </div>
    );
};

const generateUniqueRowKey = (status: S1ClientConnectionStatus): string => {
    return `MACHINEID#${status.machineId}#USERID#${status.userId}#ORG#${status.organisation}`;
};

type SortValue = {
    headerName: string;
    columnName: string;
    sortable: boolean;
};
const headerProperties: SortValue[] = [
    { headerName: 'User name', columnName: 'userId', sortable: true },
    { headerName: 'Client version', columnName: 'clientVersion', sortable: true },
    { headerName: 'Last activity', columnName: 'lastActivity', sortable: true },
    { headerName: 'Status', columnName: 'status', sortable: true },
];

export default observer(ConnectedClientsTable);
