import {
    Hub,
    JobStatus,
    Vaccination,
    VaccinationCategory,
    UpdateJobAction,
    JobType,
} from '@doc-abode/data-models';
import { IHcp } from '../../../../../interfaces';
import { GET_ROUTE_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getJob } from '../../../../../api/jobsApi';
import { reassignRoute } from '../../../../../api/routes/routesApi';
import moment from 'moment';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import useStores from '../../../../../hook/useStores';
import { useQuery, useMutation, OperationVariables, ApolloQueryResult } from '@apollo/client';
import { VaccinationRoute, VaccinationWithWarnings } from '../../types';
import {
    allowEditStatus,
    checkDateTimeWarnings,
    getValidationErrors,
    getWarnings,
    routeStatusMappings,
    routeStatusTags,
} from '../../utils';

import {
    formatDisplayDate,
    formatDisplayDateTime,
    formatDisplayTime,
    metresToMiles,
} from '../../../../modules/helpers/formatData';
import { Intent, Tag } from '@blueprintjs/core';
import RootStore from '../../../../../stores/RootStore';

interface Props {
    getAuthToken: () => Promise<string>;
    refetchRoutes: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<any>>;
    setVaccinations: () => void;
    hubs: Hub[];
    jobId: string;
    users: IHcp[];
    allPatients: Vaccination[];
    select: (id: string) => void;
    deselectAll: () => void;
    isSuperuser: boolean;
}

export const useRouteDetailsViewModel = ({
    getAuthToken,
    refetchRoutes,
    setVaccinations,
    hubs,
    jobId,
    select,
    deselectAll,
    isSuperuser,
}: Props) => {
    const {
        RootStore: {
            usersStore: { getUserHcpTypes },
            userStore: {
                user: { username },
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [aborting, setAborting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const [routeDetails, setRouteDetails] = useState<VaccinationRoute | null>(null);
    const [showAbortPrompt, setShowAbortPrompt] = useState(false);
    const [abortNotes, setAbortNotes] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedStartTime, setSelectedStartTime] = useState<Date>(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState<Date>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(new Date());
    const [loadingPatientsOnRoute, setLoadingPatientsOnRoute] = useState(true);
    const [patientsOnRoute, setPatientsOnRoute] = useState<Vaccination[]>([]);
    const [covidVaccinesOnRoute, setCovidVaccinesOnRoute] = useState<Vaccination[]>([]);
    const [daysSincePreviousDoseWarnings, setDaysSincePreviousDoseWarnings] = useState<
        VaccinationWithWarnings[]
    >([]);
    const [selectedCapacity, setSelectedCapacity] = useState<number>(0);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [hub, setHub] = useState<Hub | undefined>(undefined);
    const [hubOptions, setHubOptions] = useState<{ label: string; value: string }[]>([]);
    const history = useHistory();
    const { state } = useLocation<{
        scrollTop?: number;
        from?: string;
        reload?: boolean;
    }>();
    const modalRef = useRef<any>();
    const [selectedDateTimeWarnings, setSelectedDateTimeWarnings] = useState<string[]>([]);

    const {
        loading,
        error,
        data,
        refetch: fetchRoute,
    } = useQuery(GET_ROUTE_BY_ID, {
        variables: {
            id: jobId,
        },
        fetchPolicy: 'no-cache',
    });
    const [updateJob] = useMutation(UPDATE_JOB);

    useEffect(() => {
        if (state?.reload) {
            fetchRoute();
        }
    }, [state?.reload, fetchRoute]);

    useEffect(() => {
        if (!loading && data.getJob) {
            const response: VaccinationRoute = data?.getJob;

            setRouteDetails(response);
            setSelectedUser(response.itinerary.agent.name);
            setSelectedStartTime(new Date(response.itinerary.agent.shifts[0].startTime));
            setSelectedEndTime(new Date(response.itinerary.agent.shifts[0].endTime));
            // todo when we have time/ in another ticket  it may make more sense  to have the selected date set to the start time.
            // as long as the start time carries the correct date info
            setSelectedDate(new Date(response.itinerary.agent.shifts[0].endTime));
            setSelectedCapacity(response.itinerary.agent.capacity[0]);
        }
    }, [data, loading]);
    /**
     * our state takes date - the DateInput passes a string to the onChange handler - i think this passing of string rather than Date is due to Blueprint js upgrade
     */
    const convertDateForStateSetter = (dateString: string | null | undefined) => {
        let date;
        if (dateString) {
            date = new Date(dateString);
        }
        setSelectedDate(date);
    };

    useLayoutEffect(() => {
        if (!loadingPatientsOnRoute && state?.scrollTop && modalRef?.current) {
            modalRef.current.scrollTop = state.scrollTop;
        }
    }, [loadingPatientsOnRoute, state?.scrollTop]);

    useLayoutEffect(() => {
        if (state?.from === 'Recalculation') {
            setEditing(true);
        }
    }, [state?.from]);

    // as allPatients is not  ALL patients (it just ones filtered by filterableJobStatuses)
    // we need to get any patients we don't have in allPatients from the api/gql
    // looks like we try to find the patient and if we don't have it already we go to the call the api for
    // the missing patient(s), this looks like its done one at a time.

    useEffect(() => {
        const getPatient = async (patientId: string): Promise<Vaccination> => {
            const authToken = await getAuthToken();
            const response = await getJob(authToken, patientId);

            return response.Items[0] as Vaccination;
        };

        const getAllPatients = (patientIds: string[]): Promise<Vaccination[]> => {
            if (patientsOnRoute.length > 0) {
                return Promise.resolve(patientsOnRoute);
            } else {
                return Promise.all(
                    (patientIds || [])?.map(async (patientId) => await getPatient(patientId)),
                );
            }
        };

        const getPatients = async () => {
            const patients = await getAllPatients(patientIds);

            setPatientsOnRoute(patients);
            const covidVaccines = patients.filter(
                ({ vaccinationCategory }) => vaccinationCategory === VaccinationCategory.COVID_19,
            );
            setCovidVaccinesOnRoute(covidVaccines);
            setLoadingPatientsOnRoute(false);
            setHubOptions(
                Array.from(new Set(patients.map((patient) => patient.hubId)))
                    .map((hubId) => hubs.find((hub) => hub.id === hubId))
                    .map((hub) => ({
                        label: hub?.name || '',
                        value: hub?.id || '',
                    })),
            );
        };

        const patientIds: string[] = routeDetails?.itinerary.instructions
            .map(
                (instruction) =>
                    instruction.instructionType === 'VisitLocation' &&
                    instruction.itineraryItem?.name,
            )
            .filter((_) => _) as string[];

        if (patientIds && patientIds.length > 0) {
            getPatients();
        }

        setHub(hubs.find((hub) => hub.id === routeDetails?.hubId));
    }, [routeDetails, getAuthToken, patientsOnRoute, hubs]);

    useEffect(() => {
        const fetchWarnings = async () => {
            const warnings = await getWarnings(patientsOnRoute, selectedDate?.toISOString(), {});
            const validationErrors = getValidationErrors({
                selectedDate: selectedDate?.toISOString(),
                selectedStartTime: selectedStartTime.toISOString(),
                selectedEndTime: selectedEndTime.toISOString(),
                selectedCapacity,
                routeDetails,
                isSuperuser,
            });
            const selectDateTimeWarnings = checkDateTimeWarnings(
                selectedDate?.toISOString(),
                selectedStartTime.toISOString(),
                selectedEndTime?.toISOString(),
            );

            setSelectedDateTimeWarnings(selectDateTimeWarnings);
            setDaysSincePreviousDoseWarnings(warnings);
            setValidationErrors(validationErrors);
        };

        fetchWarnings();
    }, [
        patientsOnRoute,
        selectedDate,
        selectedStartTime,
        selectedEndTime,
        selectedCapacity,
        routeDetails,
        isSuperuser,
    ]);

    const isCovidRoute = useMemo(() => {
        return patientsOnRoute.some(
            ({ vaccinationCategory }) => vaccinationCategory === VaccinationCategory.COVID_19,
        );
    }, [patientsOnRoute]);

    if (loading) {
        return {
            loading: loading,
        };
    }

    if (!routeDetails || error) {
        return {
            routeDetails,
            error,
            loading,
        };
    }

    const {
        jobStatus,
        itinerary,
        createDateTime,
        expiryDateTime,
        hubId,
        createdBy,
        lastUpdatedBy,
        routeType,
        postVisitNotes,
    } = routeDetails;

    const expired = moment(expiryDateTime).isBefore(moment());

    const {
        agent: { name: userId, capacity, shifts },
        route: { startTime, endTime, totalTravelDistance, totalTravelTime },
        instructions,
        itineraryId,
    } = itinerary;

    const changes: string[] = [];

    if (userId !== selectedUser) {
        changes.push('USER_ID');
    }

    if (selectedCapacity !== capacity[0]) {
        changes.push('CAPACITY');
    }

    const newEndTime = moment(selectedDate)
        .hour(moment(selectedEndTime).hour())
        .minute(moment(selectedEndTime).minute())
        .toDate();

    if (!moment(routeDetails.itinerary.agent.shifts[0].endTime).isSame(newEndTime)) {
        changes.push('DATE');
    }

    if (hub?.id !== hubId) {
        changes.push('HUB');
    }

    const newStartTime = moment(selectedDate)
        .hour(moment(selectedStartTime).hour())
        .minute(moment(selectedStartTime).minute())
        .toDate();

    if (!moment(routeDetails.itinerary.agent.shifts[0].startTime).isSame(newStartTime)) {
        changes.push('START_TIME');
    }

    if (
        allowEditStatus.includes(jobStatus) &&
        (!moment(routeDetails.itinerary.agent.shifts[0].endTime).isSame(newEndTime) ||
            !moment(routeDetails.itinerary.agent.shifts[0].startTime).isSame(selectedStartTime) ||
            hub?.id !== hubId)
    ) {
        changes.push('RECALCULATE');
    }

    const isHcpUneditable = [
        JobStatus.CURRENT,
        JobStatus.ARRIVED,
        JobStatus.COMPLETED,
        JobStatus.CONTROLLER_ABORTED,
    ].includes(jobStatus);

    const onAbort = async () => {
        setShowAbortPrompt(false);
        setAborting(true);
        try {
            const input = {
                id: routeDetails.id,
                lastUpdatedBy: username,
                jobStatus: JobStatus.CONTROLLER_ABORTED,
                controllerAbortedReason: 'Job removed from Doc Abode by controller',
                controllerAbortedNotes: abortNotes?.trim(),
                version: routeDetails.version + 1,
                jobType: JobType.ROUTE,
            };

            // abort children jobs of the route
            await updateJob({
                variables: {
                    input,
                    action: UpdateJobAction.ABORT_ROUTE,
                },
            });

            // abort parent job of the route containing the itinerary
            await updateJob({
                variables: {
                    input,
                },
            });

            setVaccinations();
            await refetchRoutes();
            setPatientsOnRoute([]);
            fetchRoute();
        } catch (err) {
            console.error(err);
        }
        setAborting(false);
    };

    const onChangeCapacity = (valueAsNumber: number) => {
        if (valueAsNumber < covidVaccinesOnRoute.length) {
            setSelectedCapacity(covidVaccinesOnRoute.length);
        } else {
            setSelectedCapacity(valueAsNumber);
        }
    };
    const onChangeHub = (event: { currentTarget: { value: string } }) => {
        setHub(hubs.find((hub) => hub.id === event.currentTarget.value));
    };

    const onResubmit = async () => {
        deselectAll();
        patientsOnRoute.forEach((patient) => {
            if (patient.jobStatus === JobStatus.PENDING) {
                select(patient.id);
            }
        });
        history.push('/vaccinations/routes/new', { selectedDate });
    };

    const onSave = async () => {
        const params = {};
        const hubObj = {
            ...hub,
            compatibleWith: null,
        };

        if (changes.length === 2 && changes.includes('HUB') && changes.includes('RECALCULATE')) {
            history.push('/vaccinations/routes/recalculate', {
                route: routeDetails,
                selectedStartTime: moment(newStartTime).toISOString(),
                hub: hubObj,
            });

            return;
        }

        if (changes.includes('USER_ID')) {
            Object.assign(params, { userId: selectedUser });
        }

        if (changes.includes('CAPACITY')) {
            Object.assign(params, { capacity: selectedCapacity });
        }

        if (changes.includes('DATE')) {
            // bit of a hack to minimise the extent of the changes from having to have a separate value for selectedEndTime
            let updatedDate = moment(selectedDate)
                .hour(moment(selectedEndTime).hour())
                .minute(moment(selectedEndTime).minute());
            Object.assign(params, { date: moment.utc(updatedDate).format() });
        }

        if (changes.includes('START_TIME')) {
            Object.assign(params, { startTime: moment.utc(newStartTime).format() });
        }

        setSaving(true);
        setEditing(false);

        try {
            const authToken = await getAuthToken();
            await reassignRoute(authToken, itineraryId, params);

            const refetchedRouteInfo = await fetchRoute();
            const newRouteInfo = refetchedRouteInfo.data.getJob;
            setRouteDetails(newRouteInfo);
            await refetchRoutes();

            if (changes.includes('RECALCULATE')) {
                history.push('/vaccinations/routes/recalculate', {
                    route: newRouteInfo,
                    selectedStartTime: moment(newStartTime).toISOString(),
                    hub: changes.includes('HUB') ? hubObj : undefined,
                    userId: changes.includes('USER_ID') ? selectedUser : undefined,
                });
            }
        } catch (err) {
            setEditing(true);
            console.error(err);
        }
        setSaving(false);
    };

    const title = (
        <>
            {editing ? (
                <span className="modal__heading-text">Change route options</span>
            ) : (
                <span className="modal__heading-text">
                    {userId} - {formatDisplayDate(startTime)}
                </span>
            )}
            <Tag
                intent={routeStatusTags[jobStatus] as Intent}
                minimal={jobStatus !== JobStatus.COMPLETED}
                large
            >
                {routeStatusMappings[jobStatus]}
            </Tag>
        </>
    );

    const showPostJobNotes = shouldShowPostJobNotes({ jobStatus: jobStatus });

    return {
        formatted: {
            createDateTime: formatDisplayDateTime(routeDetails.createDateTime),
            expiryDateTime: formatDisplayDateTime(routeDetails.expiryDateTime),
            routeStartAndEndTime: `${formatDisplayTime(startTime)} to ${formatDisplayTime(
                endTime,
            )}`,
            shiftStartAndEndTime: `${formatDisplayTime(shifts[0].startTime)} to ${formatDisplayTime(
                shifts[0].endTime,
            )}`,
        },
        hub,
        jobStatus,
        instructions,
        routeDetails,
        patientsOnRoute,
        title,
        modalRef,
        saving,
        expired,
        lastUpdatedBy,
        expiryDateTime,
        aborting,
        totalTravelTime,
        createDateTime,
        createdBy,
        showPostJobNotes,
        editing,
        itineraryId,
        itinerary,
        isHcpUneditable,
        routeType,
        postVisitNotes,
        selectedCapacity,
        hubOptions,
        isCovidRoute,
        selectedUser,
        selectedEndTime,
        shifts,
        validationErrors,
        capacity,
        getUserHcpTypes,
        covidVaccinesOnRoute,
        startTime,
        endTime,
        daysSincePreviousDoseWarnings,
        selectedDate,
        showAbortPrompt,
        abortNotes,
        selectedDateTimeWarnings,
        changes,
        loadingPatientsOnRoute,
        loading,
        error,
        selectedStartTime,
        patientsLength: instructions.filter(
            ({ instructionType }) => instructionType === 'VisitLocation',
        ).length,
        totalDistanceDisplay: metresToMiles(totalTravelDistance),
        convertDateForStateSetter,
        setSelectedEndTime,
        setSelectedStartTime,
        onChangeHub,
        setEditing,
        onSave,
        onAbort,
        setAbortNotes,
        setShowAbortPrompt,
        onChangeCapacity,
        setSelectedUser,
        onResubmit,
    };
};
