import { Patient } from '@doc-abode/data-models';
import { FC } from 'react';

import { observer } from 'mobx-react';
import { createStepWarnings } from '../../../../helpers/ucr/getWarnings';
import useStores from '../../../../hook/useStores';
import { WarningType } from '../../../../interfaces/ucr';
import RootStore from '../../../../stores/RootStore';
import Warnings from '../../../v2/form/Warnings';
import { abortStatusCheck } from '../forms/common';

interface IProps {
    patient: Patient;
}

/**
 * Displays warnings and alerts relating to the patient visit.
 */
export const JobDetailsWarning: FC<IProps> = ({ patient }) => {
    const {
        RootStore: {
            usersStore: { hcpUsers },
            ucrStore: { focusedJobId, warnings: allWarnings },

            configStore: { pathways, adminTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    const stepWarnings = createStepWarnings(hcpUsers, patient, pathways, adminTypes);
    const warnings: WarningType[] = allWarnings[focusedJobId] || [];

    const hasWarnings =
        !abortStatusCheck(patient?.jobStatus) && (warnings?.length > 0 || stepWarnings?.length > 0);

    if (!hasWarnings) {
        return null;
    }
    return (
        <div className="visit-details__warning">
            {Boolean(hasWarnings) && (
                <Warnings warnings={warnings} stepWarnings={stepWarnings} spacerTop={false} />
            )}
        </div>
    );
};

export default observer(JobDetailsWarning);
